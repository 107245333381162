<template>
  <div>
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div v-if="!shipments.length && !isFilterApplied">
        <div class="items-empty-message-container">
          <div class="mb-1">
            <send-icon />
          </div>
          <b-card-text class="list-empty-text-color">
            There are no shipments yet.
          </b-card-text>
        </div>
      </div>
      <div v-else>
        <div
          class="row"
        >
          <div class="col-8">
            <div class="row">
              <div class="col-xs-12 col-lg-12 col-xl-6 col-xxl-4 mb-xl-1">
                <b-button-group class="tabs-container w-100">
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.ALL}"
                    variant="custom-secondary tab-item"
                    @click="onClickTab(TABS.ALL)"
                  >
                    {{ TABS.ALL }}
                  </b-button>
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.MARKETPLACE}"
                    variant="custom-secondary tab-item tab-filter-btn"
                    @click="onClickTab(TABS.MARKETPLACE)"
                  >
                    {{ TABS.MARKETPLACE }}
                  </b-button>
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.DIRECT}"
                    variant="custom-secondary tab-item tab-filter-btn"
                    @click="onClickTab(TABS.DIRECT)"
                  >
                    {{ TABS.DIRECT }}
                  </b-button>
                </b-button-group>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-4 p-xl-0 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1">
                <b-dropdown
                  class="filter-dropdown"
                  variant="outline-none"
                  block
                  no-caret
                  menu-class="w-100 filter-dropdown-menu scroll-bar"
                  toggle-class="filter-dropdown-toggle"
                  no-flip
                >
                  <template #button-content>
                    <div
                      class="d-flex justify-content-between"
                      :class="{ 'has-value': !!queryParams.entityName }"
                    >
                      <span> {{ queryParams.entityName || (isBrand ? 'Retailer' : 'Brand') }} </span>
                      <feather-icon icon="ChevronDownIcon" />
                    </div>
                  </template>
                  <b-dropdown-text>
                    <b-form-input
                      placeholder="Search"
                      class="filter-dropdown-toggle"
                      @input="loadEntityNames"
                    />
                    <div
                      v-if="!entityNames.length"
                      class="py-2 text-center"
                    >
                      Nothing was found. Try another {{ isBrand ? 'retailer' : 'brand' }} name.
                    </div>
                  </b-dropdown-text>
                  <b-dropdown-item
                    v-for="name in entityNames"
                    :key="name"
                    :active="queryParams.entityName === name"
                    @click="onSelectName(name)"
                  >
                    <div>
                      <span>
                        {{ name }}
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-4 mt-xs-1 mt-md-1 mt-xl-0">
                <b-form-group class="position-relative">
                  <flat-pickr
                    ref="range"
                    v-model="filterDate"
                    class="form-control bg-white date-rage-filter"
                    placeholder="Shipment date"
                    :config="{ dateFormat: KP_DATE_FORMAT.CONFIG, mode: 'range' }"
                    @on-change="onSelectRange"
                  />
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="14"
                    class="flat-pickr-caret-icon"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <b-card
          no-body
          class="mb-0"
        >
          <b-table
            v-if="isLoaded"
            ref="refshipmentListTable"
            class="position-relative custom-table"
            :per-page="queryParams.limit"
            :items="shipments"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            show-empty
            @row-clicked="openShipmentDetails"
          >

            <template #empty>
              <div class="filtered-items-empty-message-container">
                <div class="empty-icon-bg mb-1 ">
                  <refresh-icon />
                </div>
                <b-card-text class="list-empty-text-color">
                  No shipments for this request. <br>
                  Please, change your filter settings.
                </b-card-text>
              </div>
            </template>
            <!--- Column: Shipment ID -->
            <template #cell(shipmentSeqId)="data">
              <b-link
                :to="{ name: 'shipment/detail', params: { shipmentId: data.item._id } }"
              >
                <b-media vertical-align="center">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.shipmentSeqId }}
                  </span>
                </b-media>
              </b-link>
            </template>
            <!-- Column: Retailer -->
            <template #cell(userId)="data">
              <b-media vertical-align="center">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.retailerName }}
                </span>
              </b-media>
            </template>
            <!-- Column: Brand -->
            <template #cell(brandId)="data">
              <b-media vertical-align="center">
                <b-link
                  :to="{
                    name: 'brand/detail',
                    params: { brandId: data.item.brandId },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.brandName }}
                </b-link>
                <small class="text-muted">@{{ data.item.brandId }}</small>
              </b-media>
            </template>

            <!-- Column: Total Amount -->
            <template #cell(totalPrice)="data">
              <b-media vertical-align="center">
                <span class="font-weight-bold d-block text-nowrap text-right">
                  {{ formatCurrency((data.item.totalPriceAfterDiscount || data.item.totalPrice), currency(data.item)) }}
                  <span
                    v-if="isRetailer && data.item.discountInCredit"
                    v-b-tooltip.hover.bottom="`${data.item.discountInCredit} Credit Points were applied to this order`"
                    class="color-dark-blue cursor-pointer font-small-4"
                  >
                    CP
                  </span>
                </span>
              </b-media>
            </template>
            <template #cell(totalQuantity)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap text-left">
                  {{ data.item.totalQuantity }}
                </span>
              </b-media>
            </template>
            <template #head(totalPrice)>
              <b-media vertical-align="center">
                <span class="d-block text-right">
                  Total Amount
                </span>
              </b-media>
            </template>

            <!-- Column: Created At / Order Date -->
            <template #cell(createdAt)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  getDateFromTimestamp(data.item.createdAt)
                }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :class="`${parseStatus(data.item.status)}`"
                class="text-capitalize table-status"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Delivered Date -->
            <template #cell(deliveredDate)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.deliveredDate ? getDateFromTimestamp(data.item.deliveredDate) : ''
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
        <div
          v-if="hasPagination"
          class="my-2"
        >
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="queryParams.page"
                :total-rows="totalShipments"
                :per-page="queryParams.limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 page-link-class"
                prev-class="prev-item"
                next-class="next-item"
                @input="loadShipments"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownText,
  BFormInput,
  BFormGroup,
  VBTooltip,
  BCardText,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { formatNumber } from '@core/utils/utils'
import {
  dataToCurrency,
  getDateFromTimestamp,
  filterDataByDate,
} from '@/common-utils'
import { TABS } from '@/constants'
import flatPickr from 'vue-flatpickr-component'

import RefreshIcon from '@/@core/assets/svg-components/RefreshIcon.vue'
import SendIcon from '@/@core/assets/svg-components/SendIcon.vue'
import store from '@/store'
import { GET_SHIPMENT_LIST, GET_SHIPMENT_LIST_ENTITY_NAMES } from '@/store/modules/shipment.module'
import { GET_SHIPMENT_TABLE_COLUMNS } from '@/table-columns-constants'
import UserRoleMixin from '../UserRoleMixin.vue'
import { getSearchByEndpoint } from '../order/order-utils'

const { formatCurrency } = utils

const { KP_DATE_FORMAT, SHIPMENT_TEXT } = c

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BFormInput,
    BFormGroup,
    flatPickr,
    RefreshIcon,
    SendIcon,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      filterDate: null,
      isLoaded: false,
      shipments: [],
      totalShipments: 0,
      tableColumns: GET_SHIPMENT_TABLE_COLUMNS(),
      dataToCurrency,
      getDateFromTimestamp,
      filterDataByDate,
      queryParams: {
        page: 1,
        limit: 10,
      },
      TABS,
      currentTab: TABS.ALL,
      KP_DATE_FORMAT,
      entityNames: [],
    }
  },
  computed: {
    hasPagination() {
      return !!this.shipments.length
    },
    isFilterApplied() {
      return Object.keys(this.queryParams).length > 2
    },
  },
  created() {
      this.loadShipments()
      this.loadEntityNames()
  },
  methods: {
    currency(data) {
      if (this.isBrand) {
        return data.currencyForBrand
      }
      return data.currency
    },
    loadEntityNames(searchText = '') {
      const params = {
        search: searchText,
      }
      store.dispatch(GET_SHIPMENT_LIST_ENTITY_NAMES, { role: getSearchByEndpoint(this.isBrand), params }).then(res => {
        this.entityNames = res?.data?.data?.values || []
      })
    },
    onSelectName(name = '') {
      if (this.queryParams.entityName === name) {
        this.queryParams.entityName = ''
      } else {
        this.queryParams.entityName = name
      }
      this.loadShipments()
    },
    onSelectRange(dates) {
      if (dates.length) {
        if (dates.length === 2) {
          this.queryParams.minShipmentDate = dates[0]
          this.queryParams.maxShipmentDate = dates[1]
          this.loadShipments()
        }
      } else {
        delete this.queryParams.minShipmentDate
        delete this.queryParams.maxShipmentDate
        this.loadShipments()
      }
    },
    onClickTab(tab = '') {
      this.currentTab = tab
      switch (tab) {
        case TABS.ALL: {
          if (this.queryParams.hasOwnProperty('isSelfManaged')) {
            delete this.queryParams.isSelfManaged
          }
          break
        }
        case TABS.MARKETPLACE: {
            this.queryParams.isSelfManaged = false
          break
        }
        case TABS.DIRECT: {
            this.queryParams.isSelfManaged = true
          break
        }
        default: {
          break
        }
      }
      this.loadShipments()
    },
    loadShipments() {
      const params = {
        ...this.queryParams,
        page: this.queryParams.page - 1,
      }
      store.dispatch(GET_SHIPMENT_LIST, params).then(res => {
        this.shipments = res.data.data.shipments
        this.totalShipments = res.data.data.count
        this.isLoaded = true
      })
    },
    parseStatus(status) {
      if (status === SHIPMENT_TEXT.shipmentDelivered) return 'confirmed'
      return 'pending'
    },
    openShipmentDetails(data) {
      this.$router.push({
        name: 'shipment/detail',
        params: { shipmentId: data._id },
      })
    },
  },
  setup() {
    return {
      formatNumber,
      formatCurrency,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/page-common.scss';
</style>
